<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Copropriétés"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-copropriete
                class="float-left"
                variant="success"
                size="sm"
                pill
              >
                Créer une copropriété
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="coproprietes"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(modele_economique.title)="data">
            <div>
              <b-badge
                pill
                :variant="`light-${modeleEconomiqueSocieteVariant(data.item.modele_economique.title)}`"
              >
                {{ data.item.modele_economique.title }}

              </b-badge>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Voir le détail de la copropriété'"
              variant="gradient-primary"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              :to="{ name: 'espace-super-admin.detail-copropriete', params: { id: data.item.id }, replace: true }"
            >
              <feather-icon icon="ListIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Modifier un copropriete'"
              v-b-modal.modal-copropriete
              variant="gradient-warning"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Supprimer un copropriete'"
              variant="gradient-danger"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="deletingCopropriete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="coproprietes.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>
    <b-modal
      id="modal-copropriete"
      ref="myModal"
      no-close-on-backdrop
      button-size="sm"
      :modal-class="form.id ? 'modal-warning' : 'modal-success'"
      :title="form.id ? 'Modifier une copropriété' : 'Ajouter une copropriété'"
      :busy="coproprieteProcess"
      :ok-title="form.id ? 'Modifier' : 'Ajouter'"
      cancel-title="Fermer"
      cancel-variant="danger"
      :ok-variant="form.id ? 'warning' : 'success'"
      size="md"
      @hidden="resetForm"
      @ok="submitForm"
    >
      <validation-observer ref="coproprieteFormRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Nom de la copropriété"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nom de la copropriété"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    :formatter="formatter"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nom de la copropriété"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Sélectionner une société"
                label-for="societe_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="société"
                  rules="required"
                >
                  <v-select
                    id="societe_id"
                    v-model="form.societe_id"
                    label="company_name"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    :options="societes"
                    placeholder="Sélectionner une catégorie de  société"
                    :reduce="(societes) => societes.id"
                    class="select-size-sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Sélectionner un modèle économique"
                label-for="modele_economique_id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="modèle économique"
                  rules="required"
                >
                  <v-select
                    id="modele_economique_id"
                    v-model="form.modele_economique_id"
                    label="title"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    :options="modeleEconomiques"
                    placeholder="Sélectionner un modèle économique"
                    :reduce="(modeleEconomiques) => modeleEconomiques.id"
                    class="select-size-sm"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
            >
              <b-form-group
                label="Description "
                label-for="description"
              >
                <b-form-textarea
                  id="description"
                  v-model="form.description"
                  placeholder="Description "
                  rows="3"
                />

              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, watch, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  VBTooltip, BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BBadge, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput, BForm, BSpinner, VBModal,
  BFormTextarea,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/extensions, import/no-unresolved, import/no-cycle
import useCoproprietes from '@/composables/coproprieteService'
// eslint-disable-next-line import/no-cycle
import useSocietes from '@/composables/societeService'
// eslint-disable-next-line import/no-cycle
import useModeleEconomiques from '@/composables/modeleEconomiqueService'
import Swal from 'sweetalert2'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BBadge,
    BFormTextarea,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const errors = []

    const {
      getAllCoproprietes, coproprietes, createCopropriete, updateCopropriete, deleteCopropriete, loader, coproprieteProcess, coproprieteSuccess,
    } = useCoproprietes()
    const {
      getAllSocietesByCategorieSocieteId, societes,
    } = useSocietes()
    const {
      getAllModeleEconomiques, modeleEconomiques,
    } = useModeleEconomiques()
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'name', label: 'Copropriétés', sortable: true },
      { key: 'societe_syndic.company_name', label: 'Société syndic', sortable: true },
      { key: 'modele_economique.title', label: 'Modèle économique', sortable: true },
      { key: 'actions' },
    ]

    const form = reactive({
      id: null,
      name: '',
      societe_id: '',
      modele_economique_id: '',
      description: '',
    })
    /* eslint-disable global-require */

    const telephonefull = ref('')

    // eslint-disable-next-line no-unused-vars
    watch(() => (form.telephonefull), () => {
      form.phone = ''
      form.phone = telephonefull.value.formattedNumber
      console.clear()
    })
    const communes = ref([])
    const myModal = ref('')

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    // Reset Form
    const resetForm = () => {
      form.id = null
      form.name = ''
      form.societe_id = ''
      form.modele_economique_id = ''
      form.description = ''
    }

    const formatter = value => value.toUpperCase()
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    const modeleEconomiqueSocieteVariant = status => {
      if (status === 'Abonnement') return 'primary'
      if (status === 'Commission') return 'danger'
      return 'primary'
    }

    onMounted(async () => {
      await getAllCoproprietes()
      await getAllSocietesByCategorieSocieteId(1)
      await getAllModeleEconomiques()
      // Set the initial number of items
      totalRows.value = coproprietes.value.length
    })

    const coproprieteFormRules = ref('')

    const handleSubmitModal = () => {
      coproprieteFormRules.value.validate().then(async success => {
        if (success) {
          if (form.id === null) await createCopropriete({ ...form })
          else await updateCopropriete({ ...form })
          if (coproprieteSuccess.value) myModal.value.toggle('#toggle-btn')
        }
      })
    }
    // Récupérer un enregistrement
    const getItem = async item => {
      form.id = item.id
      form.name = item.name
      form.societe_id = item.societe_id
      form.modele_economique_id = item.modele_economique_id
      form.description = item.description
    }

    const deletingCopropriete = async id => {
      Swal.fire({
        title: 'Suppression',
        text: 'Etes-vous sûr de vouloir supprimer cette copropriété ?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-sm',
          cancelButton: 'btn btn-danger btn-sm ml-1',
        },
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Supprimer',
      }).then(async result => {
        if (result.isConfirmed) {
          await deleteCopropriete(id)
          await getAllCoproprietes()
          Swal.fire({

            title: 'Supprimée!',
            text: 'Société supprimée avec succès.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success btn-sm',
            },
            confirmButtonText: 'Ok',

          })
        }
      })
    }

    //  Fonction de soumission du modal
    const submitForm = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmitModal()
    }

    return {

      sortOptions,
      modeleEconomiqueSocieteVariant,
      communes,
      loader,
      coproprieteProcess,
      coproprieteSuccess,
      coproprietes,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      myModal,
      telephonefull,
      filterOn,
      fields,
      onFiltered,
      form,
      errors,
      formatter,
      resetForm,
      required,
      avatarText,
      coproprieteFormRules,
      handleSubmitModal,
      submitForm,
      societes,
      modeleEconomiques,
      getItem,
      deletingCopropriete,
    }
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
